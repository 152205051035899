import axios from "axios";

const getCsrfToken = () => {
  const csrfTokenElement = document.querySelector("meta[name=csrf-token]");
  if (csrfTokenElement) {
    return csrfTokenElement.content;
  }

  return "";
};

const apiClient = axios.create({
  baseURL: window.countryCode
});

const isCheckout = window.location.href.includes("checkout");

/* eslint-disable no-param-reassign */
apiClient.interceptors.request.use(config => {
  config.params = config.params || {};
  if (isCheckout) {
    config.params.checkout_page = true;
  }
  config.headers["X-CSRF-Token"] = getCsrfToken();
  config.headers["X-Requested-With"] = "XMLHttpRequest";
  return config;
});

// Add a response interceptor
apiClient.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  const { kameleoon_bridge_queue: kameleoonQueue } = response.data;

  (kameleoonQueue || []).forEach(experiment => {
    window.kameleoonQueue.push([
      "Experiments.assignVariation",
      experiment.id,
      experiment.variation_id
    ]);
    window.kameleoonQueue.push(["Experiments.trigger", experiment.id, true]);
  });

  return response;
});

export default apiClient;
